import React, { Component } from 'react';

import './index.scss';
import { AppStateContext } from 'src/contexts/app-state-provider.context';
import Layout from 'src/components/layout';
import { TranslatedBlock } from 'src/components/language';
import HowCanI from '../../components/how-can-i';
import AQDMLogo from '../../images/AQDM_logo.png';
import AQDMLogoFR from '../../images/AQDM_logo.png';
import DCLogo from '../../images/DC_logo.svg';
import DCLogoFR from '../../images/DC_logo_FR.svg';
import CCotBLogo from '../../images/CCotB_logo.jpg';
import CNIBLogo from '../../images/CNIB_logo.svg';
import CNIBLogoFR from '../../images/CNIB_logo_FR.svg';
import FBCLogo from '../../images/FBC_logo.png';
import FBCLogoFR from '../../images/FBC_logo.png';
import VLRLogo from '../../images/VLR_logo.svg';
import VLRLogoFR from '../../images/VLR_logo_FR.svg';
import ExternalLink from '../../components/external-link';
import Disclaimer from '../../components/disclaimer';

class PatientPage extends Component {
  static contextType = AppStateContext;
  componentDidMount() {
    this.context.setSection('patient');
    this.context.setPage('community-support');

    Array.from(document.getElementsByClassName('scrollToLink')).forEach(link => {
      link.addEventListener('click', e => {
        if (document.getElementById(link.getAttribute('scrollto'))) e.preventDefault();
        document.getElementById(link.getAttribute('scrollto')).scrollIntoView({ behavior: 'smooth', block: 'start' });
      });
    });
  }

  render() {
    let title = {
      english: 'Community Support',
      french: 'Soutien communautaire',
    };
    return (
      <Layout title={title} showNavbar={true} className="patient">
        <section className="page-header">
          <div className="container h-100">
            <div className="row h-100">
              <TranslatedBlock language="english">
                <div className="col-12 col-md-5 p-5 d-flex align-items-center">
                  <h1>
                    Community <br />
                    Support
                  </h1>
                </div>
                <div className="col-12 col-md-7 p-5">
                  <p>
                    There are a number of groups* that help support people who are living with neovascular age-related
                    macular degeneration (wet AMD) or Diabetic Macular Edema (DME). The groups are:
                  </p>
                  <ul>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Association québécoise de la dégénérescence maculaire"
                        href="#Association québécoise de la dégénérescence maculaire"
                      >
                        Association québécoise de la dégénérescence maculaire (AQDM)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Canadian Council of the Blind (CCB)"
                        href="#Canadian Council of the Blind (CCB)"
                      >
                        Canadian Council of the Blind (CCB)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Canadian National Institute of the Blind (CNIB Foundation)"
                        href="#Canadian National Institute of the Blind (CNIB Foundation)"
                      >
                        Canadian National Institute of the Blind (CNIB Foundation)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Diabetes Canada (for people who are living with DME)"
                        href="#Diabetes Canada (for people who are living with DME)"
                      >
                        Diabetes Canada (for people who are living with DME)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Fighting Blindness Canada"
                        href="#Fighting Blindness Canada"
                      >
                        Fighting Blindness Canada (FBC)
                      </a>
                      <sup>†</sup>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Vision Loss Rehabilitation Canada (VLRC)"
                        href="#Vision Loss Rehabilitation Canada (VLRC)"
                      >
                        Vision Loss Rehabilitation Canada (VLRC)
                      </a>
                    </li>
                  </ul>
                  <small>
                    <sup>†</sup>Note: this is the name of the organization. It does not provide treatment or a cure for
                    blindness or other eye diseases.
                  </small>
                </div>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <div className="col-12 col-md-5 p-5 d-flex align-items-center">
                  <h1>
                    Soutien <br />
                    communautaire
                  </h1>
                </div>
                <div className="col-12 col-md-7 p-5">
                  <p>
                    Il existe un certain nombre de groupes de soutien* pour les personnes atteintes de dégénérescence
                    maculaire liée à l’âge (DMLA) néovasculaire (forme exsudative ou humide) ou d’œdème maculaire
                    diabétique (OMD). Ces groupes sont les suivants :
                  </p>
                  <ul>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Association québécoise de la dégénérescence maculaire"
                        href="#Association québécoise de la dégénérescence maculaire"
                      >
                        Association québécoise de la dégénérescence maculaire (AQDM)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Canadian Council of the Blind (CCB)"
                        href="#Canadian Council of the Blind (CCB)"
                      >
                        Conseil canadien des aveugles (CCA)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Canadian National Institute of the Blind (CNIB Foundation)"
                        href="#Canadian National Institute of the Blind (CNIB Foundation)"
                      >
                        Institut national canadien pour les aveugles (Fondation INCA)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Diabetes Canada (for people who are living with DME)"
                        href="#Diabetes Canada (for people who are living with DME)"
                      >
                        Diabète Canada (pour les personnes atteintes d’OMD)
                      </a>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Fighting Blindness Canada"
                        href="#Fighting Blindness Canada"
                      >
                        Vaincre la cécité Canada (VCC)
                      </a>
                      <sup>†</sup>
                    </li>
                    <li>
                      <a
                        className="scrollToLink"
                        scrollto="Vision Loss Rehabilitation Canada (VLRC)"
                        href="#Vision Loss Rehabilitation Canada (VLRC)"
                      >
                        Réadaptation en déficience visuelle Canada (RDVC)
                      </a>
                    </li>
                  </ul>
                  <small>
                    <sup>†</sup> Remarque : il s’agit du nom de l’organisme. Celui-ci ne fournit aucun traitement, à
                    visée curative ou non, contre la cécité ou d’autres maladies oculaires.
                  </small>
                </div>
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <section className="container my-5 px-5" id="Association québécoise de la dégénérescence maculaire">
          <div className="row">
            <div className="col-12 col-md-7">
              <TranslatedBlock language="english">
                <h2 className="h1 ornament pb-3">Association québécoise de la dégénérescence maculaire</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="h1 ornament pb-3">Association québécoise de la dégénérescence maculaire</h2>
              </TranslatedBlock>
              <TranslatedBlock language="english">
                <p>
                  The Association québécoise de la dégénérescence maculaire (AQDM) was founded in 1990 and is dedicated
                  to supporting people with all forms of macular degeneration, including AMD. AQDM aims to bring
                  together those with the condition and their caregivers, to represent and inform them, to refer them to
                  existing resources, and to raise public awareness.
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p>
                  Fondée en 1990, l’Association québécoise de la dégénérescence maculaire (AQDM) a pour mission de
                  soutenir les personnes atteintes de toute forme de dégénérescence maculaire, dont la DMLA. L’AQDM a
                  pour objectif de rassembler les personnes atteintes de cette maladie, ainsi que leurs soignants, afin
                  de les informer, de les orienter vers les ressources offertes et de sensibiliser le public.
                </p>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <img
                loading="lazy"
                src={AQDMLogo}
                alt="Association québécoise de la dégénérescence maculaire"
                className={'img-fluid px-4'}
              />
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact AQDM?" titleFR="Quelles sont les coordonnées de l’AQDM?">
          <TranslatedBlock language="english">
            <p>
              <b>Website:</b> <ExternalLink href="https://www.aqdm.org">www.aqdm.org</ExternalLink>
            </p>
            <p>
              <b>Call Toll Free:</b> <a href="tel:1-866-867-9389">1-866-867-9389</a>
            </p>
            <p>
              <b>Email:</b>{' '}
              <a target="_blank" rel="noreferrer" href="mailto:info@aqdm.org">
                info@aqdm.org
              </a>
            </p>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <p>
              <b>Site Web :</b> <ExternalLink href="https://www.aqdm.org">www.aqdm.org</ExternalLink>
            </p>
            <p>
              <b>Numéro sans frais :</b> <a href="tel:1-866-867-9389">1-866-867-9389</a>
            </p>
            <p>
              <b>Courriel :</b>{' '}
              <a target="_blank" rel="noreferrer" href="mailto:info@aqdm.org">
                info@aqdm.org
              </a>
            </p>
          </TranslatedBlock>
        </HowCanI>

        <section className="container my-5 px-5" id="Canadian Council of the Blind (CCB)">
          <div className="row">
            <div className="col-12 col-md-7">
              <TranslatedBlock language="english">
                <h2 className="h1 ornament pb-3">Canadian Council of the Blind</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="h1 ornament pb-3">Conseil canadien des aveugles</h2>
              </TranslatedBlock>
              <TranslatedBlock language="english">
                <p>
                  Canadian Council of the Blind (CCB) is the voice of the blind in Canada. CCB brings together Canadians
                  who are blind, deaf-blind, or living with vision loss in their own communities.
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p>
                  Le Conseil canadien des aveugles (CCA) est la voix des aveugles au Canada. Le CCA réunit au sein de sa
                  collectivité des Canadiens aveugles, sourds aveugles ou touchés par une perte de vision.
                </p>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <img loading="lazy" src={CCotBLogo} alt="Canadian Council of the Blind" className={'img-fluid px-4'} />
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact CCB?" titleFR="Quelles sont les coordonnées du CCA?">
          <TranslatedBlock language="english">
            <p>
              <b>Website:</b> <ExternalLink href="https://www.ccbnational.net">www.ccbnational.net</ExternalLink>
            </p>
            <p>
              <b>Call:</b> <a href="tel:613-567-0311">613-567-0311</a>
            </p>
            <p>
              <b>Call Toll Free:</b> <a href="tel:1-877-304-0968">1-877-304-0968</a>
            </p>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <p>
              <b>Site Web :</b> <ExternalLink href="https://www.ccbnational.net">www.ccbnational.net</ExternalLink>{' '}
              (en anglais seulement)
            </p>
            <p>
              <b>Téléphone :</b> <a href="tel:613-567-0311">613-567-0311</a>
            </p>
            <p>
              <b>Numéro sans frais :</b> <a href="tel:1-877-304-0968">1-877-304-0968</a>
            </p>
          </TranslatedBlock>
        </HowCanI>

        <section className="container my-5 px-5" id="Canadian National Institute of the Blind (CNIB Foundation)">
          <div className="row">
            <div className="col-12 col-md-7">
              <TranslatedBlock language="english">
                <h2 className="h1 ornament pb-3">CNIB</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="h1 ornament pb-3">Fondation INCA</h2>
              </TranslatedBlock>
              <TranslatedBlock language="english">
                <p>
                  The CNIB Foundation was founded in 1918 and is driven to change what it is to be blind today. CNIB
                  delivers programs to people of all ages and powerful advocacy that supports people living with vision
                  loss.
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p>
                  Créée en 1918, la Fondation INCA vise à changer ce que cela veut dire que d’être aveugle dans la
                  société d’aujourd’hui. La Fondation INCA offre des programmes aux personnes de tous les âges et des
                  initiatives de défense de droits dynamiques en appui aux personnes touchées par une perte de vision.
                </p>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <TranslatedBlock language="english">
                <img loading="lazy" src={CNIBLogo} alt="CNIB" className={'img-fluid cnib-logo'} />
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <img loading="lazy" src={CNIBLogoFR} alt="CNIB" className={'img-fluid cnib-logo'} />
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact CNIB?" titleFR="Quelles sont les coordonnées de la Fondation INCA?">
          <TranslatedBlock language="english">
            <p>
              <b>Website:</b> <ExternalLink href="https://www.cnib.ca">www.cnib.ca</ExternalLink>
            </p>
            <p>
              <b>Call Toll Free:</b> <a href="tel:1-800-563-2642">1-800-563-2642</a>
            </p>
            <p>
              The CNIB is active on social media where they share information, resources, training opportunities,
              advocacy and more. Their social media channels include Facebook, Twitter, YouTube and Instagram.
            </p>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <p>
              <b>Site Web :</b> <ExternalLink href="https://www.cnib.ca">www.cnib.ca</ExternalLink>
            </p>
            <p>
              <b>Numéro sans frais :</b> <a href="tel:1-800-563-2642">1-800-563-2642</a>
            </p>
            <p>
              La Fondation INCA est active dans les médias sociaux, où elle présente des informations, des ressources,
              des activités de formation, des initiatives de défense de droits et plus encore. Elle est notamment
              présente sur Facebook, Twitter, YouTube et Instagram.
            </p>
          </TranslatedBlock>
        </HowCanI>

        <section className="container my-5 px-5" id="Diabetes Canada (for people who are living with DME)">
          <div className="row">
            <div className="col-12 col-md-7">
              <TranslatedBlock language="english">
                <h2 className="h1 ornament pb-3">Diabetes Canada</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="h1 ornament pb-3">Diabète Canada</h2>
              </TranslatedBlock>
              <TranslatedBlock language="english">
                <p>
                  Diabetes Canada’s mission is to lead the fight against diabetes by helping people with diabetes. They
                  have resources to help you better understand diabetes, share information about new research and share
                  how they are advocating for people living with diabetes.
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p>
                  Diabète Canada a pour mission de mener la lutte contre le diabète en soutenant les personnes
                  diabétiques. L’organisme propose des ressources aidant à mieux comprendre le diabète et diffuse de
                  l’information sur les travaux de recherche les plus récents et ses activités en matière de défense des
                  droits des personnes diabétiques.
                </p>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <TranslatedBlock language="english">
                <img loading="lazy" src={DCLogo} alt="Diabetes Canada" className={'img-fluid px-4 dc-logo'} />
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <img loading="lazy" src={DCLogoFR} alt="Diabetes Canada" className={'img-fluid px-4 dc-logo'} />
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact Diabetes Canada?" titleFR="Quelles sont les coordonnées de Diabète Canada?">
          <TranslatedBlock language="english">
            <p>
              <b>Website:</b> <ExternalLink href="https://www.diabetes.ca">www.diabetes.ca</ExternalLink>
            </p>
            <p>
              <b>Call:</b> <a href="tel:416-363-3373">416-363-3373</a>
            </p>
            <p>
              <b>Call Toll Free:</b> <a href="tel:1-800-226-8464">1-800-226-8464</a>
            </p>
            <p>
              <b>Email:</b>{' '}
              <a target="_blank" rel="noreferrer" href="mailto:info@diabetes.ca">
                info@diabetes.ca
              </a>
            </p>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <p>
              <b>Site Web :</b> <ExternalLink href="https://www.diabetes.ca">www.diabetes.ca</ExternalLink>{' '}
              (en anglais seulement)
            </p>
            <p>
              <b>Téléphone :</b> <a href="tel:416-363-3373">416-363-3373</a>
            </p>
            <p>
              <b>Numéro sans frais :</b> <a href="tel:1-800-226-8464">1-800-226-8464</a>
            </p>
            <p>
              <b>Courriel :</b>{' '}
              <a target="_blank" rel="noreferrer" href="mailto:info@diabetes.ca">
                info@diabetes.ca
              </a>
            </p>
          </TranslatedBlock>
        </HowCanI>

        <section className="container my-5 px-5" id="Fighting Blindness Canada">
          <div className="row">
            <div className="col-12 col-md-7">
              <TranslatedBlock language="english">
                <h2 className="h1 ornament pb-3">
                  Fighting Blindness Canada<sup>†</sup>
                </h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="h1 ornament pb-3">
                  Vaincre la cécité Canada<sup>†</sup>
                </h2>
              </TranslatedBlock>
              <TranslatedBlock language="english">
                <p>
                  Fighting Blindness Canada<sup>†</sup> (FBC) is Canada’s leading charitable funder of vision research.
                  They help to raise money to support research for diseases that cause blindness.
                </p>
                <p>
                  FCB provides resources for individuals and families impacted by blindness and provides information
                  through their website and educational events.
                </p>
                <p className="small">
                  <sup>†</sup>Note: this is the name of the organization. It does not provide treatment or a cure for
                  blindness or other eye diseases.
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p>
                  Vaincre la cécité Canada<sup>†</sup> (VCC) est un organisme de bienfaisance et le premier bailleur de
                  fonds de la recherche en santé de la vision au Canada. VCC contribue à la récolte de fonds destinés à
                  soutenir la recherche sur les maladies occasionnant la cécité.
                </p>
                <p>
                  VCC offre des ressources aux personnes et aux familles touchées par la cécité et donne de
                  l’information par l’intermédiaire de son site Web et d’activités éducatives.
                </p>
                <p className="small">
                  <sup>†</sup> Remarque : il s’agit du nom de l’organisme. Celui-ci ne fournit aucun traitement, à visée
                  curative ou non, contre la cécité ou d’autres maladies oculaires.
                </p>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <TranslatedBlock language="english">
                <img
                  loading="lazy"
                  src={FBCLogo}
                  alt="Fighting Blindness Canada"
                  className={'img-fluid px-4 dc-logo'}
                />
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <img
                  loading="lazy"
                  src={FBCLogoFR}
                  alt="Vaincre la cécité Canada"
                  className={'img-fluid px-4 dc-logo'}
                />
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <HowCanI titleEN="How can I contact FBC?" titleFR="Quelles sont les coordonnées de VCC?">
          <TranslatedBlock language="english">
            <p>
              <b>Website:</b>{' '}
              <ExternalLink href="https://www.fightingblindness.ca">www.fightingblindness.ca</ExternalLink>
              <sup>†</sup>
            </p>
            <p>
              <b>Call Toll Free:</b> <a href="tel:1-888-626-2995">1-888-626-2995</a>
            </p>
            <p className="small">
              <sup>†</sup>Note: this is the name of the organization. It does not provide treatment or a cure for
              blindness or other eye diseases.
            </p>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <p>
              <b>Site Web :</b>{' '}
              <ExternalLink href="https://www.fightingblindness.ca">www.fightingblindness.ca</ExternalLink>
              <sup>†</sup>
            </p>
            <p>
              <b>Numéro sans frais :</b> <a href="tel:1-888-626-2995">1-888-626-2995</a>
            </p>
            <p className="small">
              <sup>†</sup> Remarque : il s’agit du nom de l’organisme. Celui-ci ne fournit aucun traitement, à visée
              curative ou non, contre la cécité ou d’autres maladies oculaires.
            </p>
          </TranslatedBlock>
        </HowCanI>

        <section className="container my-5 px-5" id="Vision Loss Rehabilitation Canada (VLRC)">
          <div className="row">
            <div className="col-12 col-md-7">
              <TranslatedBlock language="english">
                <h2 className="h1 ornament pb-3">Vision Loss Rehabilitation Canada</h2>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <h2 className="h1 ornament pb-3">Réadaptation en déficience visuelle Canada</h2>
              </TranslatedBlock>
              <TranslatedBlock language="english">
                <p>
                  Vision Loss Rehabilitation Canada (VLRC) is a not-for-profit national healthcare organization and the
                  leading provider of rehabilitation therapy and healthcare services for individuals with vision loss.
                </p>
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <p>
                  Réadaptation en déficience visuelle Canada (RDVC) est un organisme canadien de soins de santé sans but
                  lucratif et un chef de file en thérapies de réadaptation et services de soins de santé à des personnes
                  vivant avec une perte de vision.
                </p>
              </TranslatedBlock>
            </div>
            <div className="col-12 col-md-5 d-flex align-items-center">
              <TranslatedBlock language="english">
                <img
                  loading="lazy"
                  src={VLRLogo}
                  alt="Vision Loss Rehabilitation Canada"
                  className={'img-fluid px-4'}
                />
              </TranslatedBlock>
              <TranslatedBlock language="french">
                <img
                  loading="lazy"
                  src={VLRLogoFR}
                  alt="Vision Loss Rehabilitation Canada"
                  className={'img-fluid px-4'}
                />
              </TranslatedBlock>
            </div>
          </div>
        </section>

        <HowCanI
          titleEN="How can I contact Vision Loss Rehabilitation Canada?"
          titleFR="Quelles sont les coordonnées de Réadaptation en déficience visuelle Canada?"
        >
          <TranslatedBlock language="english">
            <p>
              <b>Website:</b> <ExternalLink href="https://www.visionlossrehab.ca">www.visionlossrehab.ca</ExternalLink>
            </p>
            <p>
              <b>Call Toll Free:</b> <a href="tel:1-844-887-8572">1-844-887-8572</a>
            </p>
            <p>
              <b>Email:</b>{' '}
              <a target="_blank" rel="noreferrer" href="mailto:info@vlrehab.ca">
                info@vlrehab.ca
              </a>
            </p>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <p>
              <b>Site Web :</b>{' '}
              <ExternalLink href="https://www.visionlossrehab.ca/fr">www.visionlossrehab.ca/fr</ExternalLink>
            </p>
            <p>
              <b>Numéro sans frais :</b> <a href="tel:1-844-887-8572">1-844-887-8572</a>
            </p>
            <p>
              <b>Courriel :</b>{' '}
              <a target="_blank" rel="noreferrer" href="mailto:info@vlrehab.ca">
                info@vlrehab.ca
              </a>
            </p>
          </TranslatedBlock>
        </HowCanI>

        <TranslatedBlock language="english">
          <p className={'text-center mt-4 disclaimer'}>* Websites owned and operated by independent third parties.</p>
        </TranslatedBlock>
        <TranslatedBlock language="french">
          <p className={'text-center mt-4 disclaimer'}>
            * Ces sites Web appartiennent à des tiers indépendants et sont gérés par ceux-ci.
          </p>
        </TranslatedBlock>

        <div className={'d-flex justify-content-center align-items-center mx-auto p-5 mt-5 quote'}>
          <TranslatedBlock language="english">
            <p className={'text-center mt-4'}>Talk to your eye doctor if you have specific questions.</p>
          </TranslatedBlock>
          <TranslatedBlock language="french">
            <p className={'text-center mt-4'}>Consultez votre ophtalmologiste si vous avez des questions.</p>
          </TranslatedBlock>
        </div>

        <Disclaimer />
      </Layout>
    );
  }
}

export default PatientPage;
